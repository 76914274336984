<template>
  <div class="play-button">
    <div class="play-button__inner">
      <svg
        width="31"
        height="34"
        viewBox="0 0 31 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="play-button__icon play-button__icon--desktop"
      >
        <path
          d="M28.8917 14.6594C30.6935 15.6997 30.6935 18.3004 28.8917 19.3406L4.56742 33.3843C2.76562 34.4246 0.513365 33.1242 0.513365 31.0437L0.513366 2.95639C0.513366 0.875846 2.76562 -0.424497 4.56742 0.615774L28.8917 14.6594Z"
          fill="#F0EFFD"
        />
      </svg>
    </div>
    <div class="play-button__pulse-wrapper">
      <div class="play-button__pulse"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;

  border-radius: 50%;
  background-color: var(--cobalt-600);
  cursor: pointer;
  box-shadow: 0 1px 20px rgba(196, 196, 196, 0.25);
  @media (min-width: $breakpoint-md) {
    width: 100px;
    height: 100px;
  }

  &__inner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    position: absolute;
    fill: var(--cobalt-100);
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 17px;
    height: 20px;
    display: block;
    @media (min-width: $breakpoint-md) {
      width: 43px;
      height: 50px;
      display: block;
    }
  }

  &__pulse-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: var(--cobalt-600);
    animation: pulse 2s ease-in-out infinite;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale3d(1.42, 1.42, 1);
  }
}
</style>
