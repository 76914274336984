<template>
  <div
    ref="videoPlayerRef"
    :class="['video-player', shadowClass]"
    :style="scaleStyle"
  >
    <button
      v-if="!isVideoPlayed"
      type="button"
      class="video-player__thumbnail-wrapper"
      @click="playVideo"
    >
      <BaseNuxtImage
        class="video-player__thumbnail-img"
        :src="blok.thumbnail.filename"
        :alt="blok.thumbnail.alt || ''"
      />
      <span v-if="blok.title" class="video-player__label video-player__title">
        {{ blok.title }}
      </span>
      <span v-if="blok.duration" class="video-player__label video-player__time">
        {{ blok.duration }}
      </span>
      <BasePlayButton />
    </button>
    <video
      v-else
      controls
      autoplay
      controlslist="nodownload"
      class="video-player__video"
      :src="blok.video.filename"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SbVideoPlayer } from '@/types'
import { useIntersectionObserver } from '@vueuse/core'

interface Props {
  blok: SbVideoPlayer
}

const props = defineProps<Props>()

const isVideoPlayed = ref(false)
const videoPlayerRef = ref<HTMLElement | null>(null)
const scale = ref(0.8)

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  const entry = entries[0]
  if (!entry || !props.blok.enable_scale_animation) return

  const viewportHeight = window.innerHeight
  const elementTop = entry.boundingClientRect.top
  const ANIMATION_RATE = 3000 // the bigger the number, the slower the animation

  const scrollProgress = Math.max(
    0,
    Math.min(1, (viewportHeight - elementTop) / ANIMATION_RATE),
  )
  scale.value = 0.9 + Math.max(0, Math.min(0.1, scrollProgress))
}

if (props.blok.enable_scale_animation) {
  useIntersectionObserver(videoPlayerRef, handleIntersection, {
    threshold: Array.from({ length: 100 }, (_, i) => i / 100),
    root: null,
    rootMargin: '0px',
  })
}

const scaleStyle = computed(() => ({
  transform: props.blok.enable_scale_animation
    ? `scale(${scale.value})`
    : undefined,
  transformOrigin: 'center center',
}))

const playVideo = () => {
  isVideoPlayed.value = true
}

const shadowClass = computed(() => props.blok.shadow || '')
</script>

<style lang="scss" scoped>
.video-player {
  height: 0;
  width: 100%;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  transition: transform 0.1s ease-out;
  will-change: transform;

  @media (min-width: $breakpoint-lg) {
    border-radius: var(--border-radius-extra-large);
  }

  &__thumbnail-img,
  &__video {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__label {
    @include paragraph-2xs;
    @include shadow-flat-soft;

    position: absolute;
    padding: 2px 4px;
    background-color: var(--white);
    border-radius: var(--border-radius-small);

    @media (min-width: $breakpoint-lg) {
      @include paragraph-m;

      border-radius: var(--border-radius-base);
      padding: 6px 12px;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: var(--gray-200) url('@/assets/icons/polygon.svg') no-repeat;
    background-size: 16px auto;
    background-position: 20px 50%;
    transition: transform 0.2s;
    transform-origin: top right;

    @media (min-width: $breakpoint-lg) {
      width: 148px;
      height: 148px;
      background-size: 44px auto;
      background-position: 58px 50%;
    }

    &:hover {
      transform: scale(1.1) translate(50%, -50%);
    }
  }

  &__title {
    top: 16px;
    left: 16px;

    @media (min-width: $breakpoint-lg) {
      top: 40px;
      left: 40px;
    }
  }

  &__time {
    bottom: 16px;
    right: 16px;

    @media (min-width: $breakpoint-lg) {
      bottom: 40px;
      right: 40px;
    }
  }
}
</style>
